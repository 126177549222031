<template>
  <v-card color="basil">
    <v-card-title class="text-center justify-center py-6">
      <h1 class="font-weight-bold text-h2 basil--text">
        {{ $t("Member Setting") }}
      </h1>
    </v-card-title>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      slider-color="green"
      grow
    >
      <v-tab v-for="item in items" :key="item">
        <h5 class="text-h5 text-typo">{{ item }}</h5>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="item">
        <component v-bind:is="tabComponents[index]"></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  name: "member-setting",
  data: (vm) => ({
    tab: null,
    items: [
      vm.$i18n.t("Setting"),
      vm.$i18n.t("Recharge Order"),
      vm.$i18n.t("Shop Order"),
      vm.$i18n.t("Point Use"),
    ],
    tabComponents: [
      "user-settings",
      "recharge-order",
      "shop-order",
      "point-use",
    ],
  }),
  components: {
    "user-settings": () => import("@/views/Pages/General/UserSettings"),
    "recharge-order": () => import("@/views/Pages/DIY/OrderOfRecharge.vue"),
    "shop-order": () => import("@/views/Pages/DIY/OrderOfShop.vue"),
    "point-use": () => import("@/views/Pages/DIY/OrderOfPointUse.vue"),
  },
};
</script>
